import React, {Component} from 'react';
import './Monitor.less'
import {Card, Col, Row, Space} from "antd";
import FrpTcpTable from "./data/FrpTcpTable";

export default class Monitor extends Component {


    render() {
        return (
            <div className="Monitor">
                <Space direction="vertical" style={{width: '100%'}}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="内网穿透连接详情" bordered={false} >
                                <FrpTcpTable />
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </div>
        );
    }
}

