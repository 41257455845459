import React from 'react';
import Login from '../login/Login'
import './UserLayout.less'
import {Route, Switch} from 'react-router-dom'
import Footer from '../Footer'
import {Layout} from "antd";
import CloudAbout from "../About";

const {Content} = Layout;

function UserLayout() {
    return (
        <Layout>
            <Content>
                <div className='main-login'>
                    <div className="flex-main">
                        <div className='logo'>
                            CassieCloud
                        </div>
                        <Switch>
                            <Route key={1} path='/user/login' component={Login}/>
                            <Route key={2} path='/user/about' component={CloudAbout}/>
                        </Switch>
                    </div>
                </div>
            </Content>
            <Footer/>
        </Layout>
    )

}

export default UserLayout;