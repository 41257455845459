import {createStore} from 'redux'
import rootReducers from './reducers'

import {composeWithDevTools} from 'redux-devtools-extension';
// composeWithDevTools 在控制台可以查看数据
const datastore = createStore(rootReducers, composeWithDevTools())

console.log("initial state: ", datastore.getState());
datastore.subscribe(() => console.log("new state:", datastore.getState()));

export default datastore;