import React, {Component} from 'react'
import {Button, Card, Col, Form, FormInstance, Input, message, Modal, Row, Select, Space, Table} from "antd";
import {MovieDownInfo} from "../common/common";
import {Ajax} from "../api/axios";

const {Column} = Table;

interface IProps {

}

interface IStats {
    movieDownInfos: Array<MovieDownInfo>;
    visible: boolean;
    movieDownInfo: MovieDownInfo;
    loading: boolean;
}

const {Search} = Input;
const {Option} = Select;

class TVNameConfig extends Component<IProps, IStats> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            movieDownInfos: [],
            visible: false,
            movieDownInfo: {
                id: 0,
                tvName: '',
                tvFullName: '',
                tvDownPath: '',
                tvVersion: '',
                isDown: 0
            },
            loading: true
        };
    };

    formRef = React.createRef<FormInstance>();
    formRef1 = React.createRef<FormInstance>();


    componentDidMount() {
        Ajax.get<Array<MovieDownInfo>>('/moviedown/query').then(res => {
            if (res.success) {
                console.log("get data sucess");
                this.setState({
                    movieDownInfos: res.data,
                    loading: false
                });
            }
        }).catch(function (reason) {
            console.log('catch:', reason);
            message.error('获取数据失败', 3);
        })
    }

    onSearch = (value: MovieDownInfo) => {
        console.log('query param: ', value);
        this.setState({
            loading: true
        });
        let tvFullName = "";
        if (value.tvFullName != undefined) {
            tvFullName = value.tvFullName;
        }
        let url = '/moviedown/query?tv_full_name=' + tvFullName;
        if (value.isDown != undefined) {
            url = url + '&is_down=' + value.isDown;
        }

        Ajax.get<Array<MovieDownInfo>>(url).then(res => {

            if (res.success) {
                console.log("get data sucess");
                this.setState({
                    loading: false,
                    movieDownInfos: res.data
                });
            }
        }).catch(function (reason) {
            console.log('catch:', reason);
            message.error('获取数据失败', 3);
        })
    };

    onUpdate = (record: MovieDownInfo) => {
        console.log("update record:" + record.tvFullName);
        this.setVisible(true);
        if (this.state.movieDownInfo.id === 0) {
            this.setState({
                movieDownInfo: record
            });
        } else {
            this.formRef.current!.setFieldsValue({
                tvName: record.tvName,
                tvFullName: record.tvFullName,
                tvDownPath: record.tvDownPath,
                tvVersion: record.tvVersion,
                isDown: record.isDown,
                id: record.id
            });
        }

    };

    setVisible = (visible: boolean) => {
        this.setState({
            visible: visible
        });
    }

    onCancel = () => {
        console.log("cancel update data.");
        this.formRef.current!.resetFields();
        this.setVisible(false);
    };

    onClear = () => {
        console.log("cancel search data.");
        this.formRef1.current!.resetFields();
    };

    onOk = () => {
        this.formRef.current!.validateFields().then(values => {
            var movieDownInfo: MovieDownInfo = values;
            if (this.state.movieDownInfo.isDown === movieDownInfo.isDown && this.state.movieDownInfo.tvDownPath === movieDownInfo.tvDownPath && this.state.movieDownInfo.tvVersion === movieDownInfo.tvVersion) {
                console.log("data is not change.")
            } else {
                Ajax.post<number>("/moviedown/update", movieDownInfo).then(res => {
                    if (res.success) {
                        console.log("update data sucess");
                        let datas = [...this.state.movieDownInfos];
                        const index = datas.findIndex(item => movieDownInfo.id === item.id);
                        if (index > -1) {
                            const item = datas[index];
                            datas.splice(index, 1, {
                                ...item,
                                ...movieDownInfo,
                            });
                            this.setState({
                                movieDownInfos: datas
                            });
                        }
                    }
                }).catch(function (reason) {
                    console.log('catch:', reason);
                    message.error('更新数据失败', 3);
                })
            }
        }).catch(function (reason) {
            console.log('catch:', reason);
            message.error('数据检查失败', 3);
        })

        this.setVisible(false);
    };


    render() {

        return (
            <div className="tvNameConfig">
                <Space direction="vertical" style={{width: '100%'}}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form
                                name="search-form"
                                ref={this.formRef1}
                                onFinish={this.onSearch}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={6}>
                                        <Form.Item
                                            name="tvFullName"
                                            label="中文名"
                                        >
                                            <Input placeholder="请输入中文名" size="middle"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="isDown"
                                            label="是否下载"
                                        >
                                            <Select style={{width: 120}} placeholder="请选择">
                                                <Option value={0}>否</Option>
                                                <Option value={1}>是</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} offset={6}>
                                        <Button type="primary" htmlType="submit">
                                            搜索
                                        </Button>
                                        <Button
                                            style={{margin: '0 8px'}}
                                            onClick={this.onClear}
                                        >
                                            重置
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="影视信息" bordered={false}>
                                <Table dataSource={this.state.movieDownInfos} loading={this.state.loading}>
                                    <Column title="中文名" dataIndex="tvFullName" width="25%"/>
                                    <Column title="英文名" dataIndex="tvName" width="20%"/>
                                    <Column title="保存路径" dataIndex="tvDownPath" width="25%"/>
                                    <Column title="下载版本" dataIndex="tvVersion" width="10%" />
                                    <Column title="是否下载" dataIndex="isDown" width="10%"
                                            render={function (isDown: number) {
                                                if (isDown === null || isDown === 0) {
                                                    return '否';
                                                } else {
                                                    return '是';
                                                }
                                            }}/>
                                    <Column title="操作" dataIndex="action" width="10%"
                                            render={(text: string, record: MovieDownInfo) => (
                                                <Space size="middle">
                                                    <Button onClick={() => this.onUpdate(record)}>
                                                        更新
                                                    </Button>
                                                </Space>
                                            )}/>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Space>
                <Modal title="更新字幕下载配置" visible={this.state.visible} okText="保存"
                       cancelText="取消"
                       onCancel={this.onCancel}
                       onOk={this.onOk}
                >
                    <Form
                        name="subupdate-form"
                        ref={this.formRef}
                        initialValues={this.state.movieDownInfo}
                    >
                        <Form.Item
                            name="tvFullName"
                            label="中文名"
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                        <Form.Item
                            name="tvDownPath"
                            label="保存路径"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="tvVersion"
                            label="下载版本"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="isDown"
                            label="是否下载"
                        >
                            <Select style={{width: 120}}>
                                <Option value={0}>否</Option>
                                <Option value={1}>是</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="id"
                            hidden={true}
                        >
                        </Form.Item>
                        <Form.Item
                            name="tvName"
                            hidden={true}
                        >
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
};

export default TVNameConfig;