import React from 'react';
import { Layout } from 'antd';
import Header from '../Header'
import Footer from '../Footer'
import MainRoutes from '../../router/MainRoutes'
const { Content } = Layout;



export function BasicLayout(){

    return (
        <Layout style={{minHeight:"100%"}}>
            <Layout>
                <Header />
                <Content>
                    <MainRoutes />
                </Content>
                <Footer/>
            </Layout>
        </Layout>
    )

}

export default BasicLayout;