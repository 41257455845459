import React from 'react';
import './App.css';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'

import BasicLayout from './component/layout/BasicLayout'
import UserLayout from './component/layout/UserLayout'
import {AuthRouter} from "./router/AuthRouter";

function App() {
    return (
        <HashRouter>
            <Switch>
                {/* <Redirect exact from='/' to='/home' /> */}
                <Redirect exact from='/user' to='/user/login' />
                <Route path="/user" component={UserLayout} />
                <AuthRouter path="/" component={BasicLayout} />
            </Switch>
        </HashRouter>
    );
}

export default App;
