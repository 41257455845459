import React, {Component} from 'react'
import {
    Button,
    Card,
    Col,
    Form,
    FormInstance,
    Input,
    message,
    Modal,
    Row,
    Select,
    Space,
    Table,
    TablePaginationConfig
} from "antd";
import {MovieSubInfo, MovieSubPages, PageInfo} from "../common/common";
import {Ajax} from "../api/axios";
import {Key, TableRowSelection} from 'antd/lib/table/interface';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';

const {Column} = Table;

interface IProps {

}

interface IStats {
    movieSubInfos: Array<MovieSubInfo>;
    visible: boolean;
    movieSubInfo: MovieSubInfo;
    loading: boolean;
    pageInfo: PageInfo;
    query: string;
    selectedRowKeys: Key[];
    confirmLoading: boolean;
    delVisible: boolean;
}

const {Search} = Input;
const {Option} = Select;

class SubConfig extends Component<IProps, IStats> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            movieSubInfos: [],
            visible: false,
            movieSubInfo: {
                id: 0,
                file: '',
                fileName: '',
                filePath: '',
                isSub: 0,
                queryName: ''
            },
            pageInfo: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            query: '',
            selectedRowKeys: [],
            loading: true,
            confirmLoading: false,
            delVisible: false
        };
    };

    formRef = React.createRef<FormInstance>();

    getData = (query: string, pageNo: number, pageSize: number) => {
        Ajax.get<MovieSubPages>('/moviesub/query?file_name=' + query + '&pageNo=' + pageNo + '&pageSize=' + pageSize).then(res => {

            if (res.success) {
                console.log("get data sucess");
                this.setState({
                    movieSubInfos: res.data.movieSubInfos,
                    pageInfo: {
                        pageSize: res.data.pageSize,
                        current: res.data.pageNo,
                        total: res.data.total
                    },
                    loading: false
                });
            }
        }).catch(function (reason) {
            console.log('catch:', reason);
            message.error('获取数据失败', 3);
        })
    }


    componentDidMount() {
        this.getData('', this.state.pageInfo.current, this.state.pageInfo.pageSize);
    }

    handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        console.log("start next page:", pagination);
        this.setState({
            loading: true
        });
        var pageNo = 1;
        if (pagination.current != null) {
            pageNo = pagination.current;
        }
        var pageSize = 10;
        if (pagination.pageSize != null) {
            pageSize = pagination.pageSize;
        }

        this.getData(this.state.query, pageNo, pageSize);
    };

    onSearch = (value: string) => {
        console.log('query param: ', value);
        this.setState({
            loading: true,
            query: value
        });

        this.getData(value, 1, 10);

    };

    onUpdate = (record: MovieSubInfo) => {
        console.log("update record:" + record.fileName);
        this.setVisible(true);
        if (this.state.movieSubInfo.id === 0) {
            this.setState({
                movieSubInfo: record
            });
        } else {
            this.formRef.current!.setFieldsValue({
                fileName: record.fileName,
                queryName: record.queryName,
                isSub: record.isSub,
                id: record.id,
                file: record.file,
                filePath: record.filePath
            });
        }

    };

    setVisible = (visible: boolean) => {
        this.setState({
            visible: visible
        });
    };

    setDelVisible = (delVisible: boolean) => {
        this.setState({
            delVisible: delVisible
        });
    };

    setConfirmLoading = (confirmLoading: boolean) => {
        this.setState({
            confirmLoading: confirmLoading
        })
    };

    onCancel = () => {
        console.log("cancel update data.");
        this.formRef.current!.resetFields();
        this.setVisible(false);
    };

    onSelectChange = (selectedRowKeys: Key[], selectedRows: Array<MovieSubInfo>) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        console.log('selected rows:', selectedRows);
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    };

    onOk = () => {
        this.formRef.current!.validateFields().then(values => {
            var movieSubInfo: MovieSubInfo = values;
            if (this.state.movieSubInfo.isSub === movieSubInfo.isSub && this.state.movieSubInfo.queryName === movieSubInfo.queryName) {
                console.log("data is not change.")
            } else {
                Ajax.post<number>("/moviesub/update", movieSubInfo).then(res => {
                    if (res.success) {
                        console.log("update data sucess");
                        let datas = [...this.state.movieSubInfos];
                        const index = datas.findIndex(item => movieSubInfo.id === item.id);
                        if (index > -1) {
                            const item = datas[index];
                            datas.splice(index, 1, {
                                ...item,
                                ...movieSubInfo,
                            });
                            this.setState({
                                movieSubInfos: datas
                            });
                        }
                    }
                }).catch(function (reason) {
                    console.log('catch:', reason);
                    message.error('更新数据失败', 3);
                })
            }
        }).catch(function (reason) {
            console.log('catch:', reason);
            message.error('数据检查失败', 3);
        });

        this.setVisible(false);
    };

    onDel = () => {
        const length = this.state.selectedRowKeys.length;
        if (length <= 0) {
            message.warning("请选择要删除的数据", 5);
        } else {
            this.setDelVisible(true);
        }
    };

    handleDelCancel = () => {
        console.log("cancel del data.");
        this.setDelVisible(false);
    };

    handleDelOk = () => {
        console.log("del data.");
        this.setConfirmLoading(true);
        Ajax.post<number>("/moviesub/del", this.state.selectedRowKeys).then(res => {
            if (res.success) {
                console.log("del data sucess");
                this.setState({
                    loading: true
                });
                this.getData(this.state.query, this.state.pageInfo.current, this.state.pageInfo.pageSize);
            } else {
                message.warning("数据删除失败", 5);
            }
        }).catch(function (reason) {
            console.log('catch:', reason);
            message.error('删除数据失败', 3);
        });

        this.setConfirmLoading(false);
        this.setDelVisible(false);
    };


    render() {

        const rowSelection: TableRowSelection<MovieSubInfo> = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div className="SubConfig">
                <Space direction="vertical" style={{width: '100%'}}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Search placeholder="input search text" onSearch={this.onSearch} enterButton/>
                        </Col>
                        <Col span={6}>
                            <Button type="primary" icon={<DeleteOutlined/>} onClick={this.onDel}>删除</Button>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="影视信息" bordered={false}>
                                <Table dataSource={this.state.movieSubInfos} loading={this.state.loading}
                                       pagination={this.state.pageInfo} onChange={this.handleTableChange}
                                       rowSelection={rowSelection} rowKey="id">
                                    <Column title="文件名" dataIndex="fileName" width="50%"/>
                                    <Column title="字幕查询条件" dataIndex="queryName" width="20%"/>
                                    <Column title="是否已下载字幕" dataIndex="isSub" width="20%"
                                            render={function (isSub: number) {
                                                if (isSub === 0) {
                                                    return '否';
                                                } else {
                                                    return '是';
                                                }
                                            }}/>
                                    <Column title="操作" dataIndex="action" width="10%"
                                            render={(text: string, record: MovieSubInfo) => (
                                                <Space size="middle">
                                                    <Button onClick={() => this.onUpdate(record)}>
                                                        更新
                                                    </Button>
                                                </Space>
                                            )}/>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Space>
                <Modal title="更新字幕下载配置" visible={this.state.visible} okText="保存"
                       cancelText="取消"
                       onCancel={this.onCancel}
                       onOk={this.onOk}
                >
                    <Form
                        name="subupdate-form"
                        ref={this.formRef}
                        initialValues={this.state.movieSubInfo}
                    >
                        <Form.Item
                            name="fileName"
                            label="文件名"
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                        <Form.Item
                            name="queryName"
                            label="字幕查询条件"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="isSub"
                            label="是否下载字幕"
                        >
                            <Select style={{width: 120}}>
                                <Option value={0}>否</Option>
                                <Option value={1}>是</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="id"
                            hidden={true}
                        >
                        </Form.Item>
                        <Form.Item
                            name="file"
                            hidden={true}
                        >
                        </Form.Item>
                        <Form.Item
                            name="filePath"
                            hidden={true}
                        >
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title="数据删除确认"
                    visible={this.state.delVisible}
                    onOk={this.handleDelOk}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleDelCancel}
                    okText="确定"
                    okType="danger"
                    cancelText="取消"
                >
                    <p><ExclamationCircleOutlined/>确定要删除这些数据？</p>
                </Modal>
            </div>
        );
    }
};

export default SubConfig;