import React from 'react';
import {Route, Redirect} from 'react-router-dom';


const DATA = {
    token: sessionStorage.getItem("access-token")
}


const AuthRouter = (props: any) => {
    const {component: Component, needLogin} = props;
    DATA.token = sessionStorage.getItem("access-token");
    if (needLogin === false || logined()) {
        return (<Route key={props.location.pathname} {...props} component={Component}/>);
    } else {
        return <Redirect exact {...props} from={props.path} to={'/user/login'}/>
    }
}

const logined = () => {
    if (DATA.token != null) {
        return true;
    } else {
        return false;
    }
}

export {AuthRouter}