export const asideData = [
    {
        menu: '工作台',
        url: '/home',
        child: null
    },
    {
        menu: '监控',
        url: '/monitor',
        child: null
    },
    {
        menu: 'TV时间表',
        url: '/tvcalendar',
        child: null
    },
    {
        menu: '配置',
        child: [
            {
                item: '字幕下载',
                url: '/subconfig',
                child: null
            },
            {
                item: '美剧下载',
                url: '/tvconfig',
                child: null
            }
        ]
    }
]