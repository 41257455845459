import React, {Component} from 'react'
import {Ajax} from "../../api/axios";
import {FrpServerInfo} from "../../common/common"
import {Statistic} from "antd";

interface IProps {

}

interface IState {
    data: FrpServerInfo
}

class FrpStatis extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            data: {
                bind_port: 0,
                bind_udp_port: 0,
                client_counts: 0,
                cur_conns: 0,
                heart_beat_timeout: 0,
                kcp_bind_port: 0,
                max_pool_count: 0,
                max_ports_per_client: 0,
                total_traffic_in: 0,
                total_traffic_out: 0,
                version: '',
                vhost_http_port: 0,
                vhost_https_port: 0
            }
        };
    }

    componentDidMount() {
        Ajax.get<FrpServerInfo>('/frps/serverinfo').then(res => {
            console.log(res);

            if (res.success) {
                console.log("get data sucess");
                this.setState({
                    data: res.data
                })
                console.log("data:", this.state);
            }
        })
    }


    public render() {

        return (
            <div>
                <Statistic title="当前客户端" value={this.state.data.client_counts}/>
                <Statistic title="当前连接数" value={this.state.data.cur_conns}/>
            </div>
        );
    }
}

export default FrpStatis;