import React, {Component} from 'react'
import {Card} from 'antd';
import ZabbixStatis from './data/ZabbixStatis';

const tabList = [
    {
        key: 'omv',
        tab: 'OMV Host',
    },
    {
        key: 'Zabbix server',
        tab: 'Linux Host',
    },
];

interface IProps {

}

interface IState {
    key: string
}

class HostCard extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            key: 'omv'
        }
    }

    onTabChange = (key: string) => {
        this.setState({key: key});
    };

    render() {
        return (
            <Card
                title="虚拟机情况"
                style={{height: 400}}
                tabList={tabList}
                activeTabKey={this.state.key}
                onTabChange={key => {
                    this.onTabChange(key);
                }}
            >
                <ZabbixStatis hostName={this.state.key} height={200} />
            </Card>
        );
    }
}

export default HostCard;