import React, {Component} from 'react';
import {Form, Input, Button, message, Row, Col} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import './Login.less'
import {LoginParam} from '../../common/common';
import {Ajax} from '../../api/axios'

import {connect} from 'react-redux';
import * as userActions from '../../redux/user/userAction'
import {createHashHistory} from "history";

interface IProps {
    onUserAdd: (username: string, status: number) => void
}

class NormalLoginForm extends Component<IProps> {

    onFinish = (values: LoginParam) => {
        console.log('Received values of form: ', values);
        this.login(values);
    };


    login = (val: LoginParam) => {
        Ajax.post('/user/login', {
            "username": val.username,
            "password": val.password
        }).then(res => {
            console.log(res);

            if (res.success) {
                console.log("login success");
                sessionStorage.setItem("access-token", "1");
                // @ts-ignore
                this.props.onUserAdd(res.data.username, res.data.status);
                // 跳转到管理界面 不需要回退用replace
                createHashHistory().replace('/home')
            } else {
                console.log("login fail.")
                message.error('登录失败', 3);
            }
        }).catch(function (reason) {
            console.log('catch:', reason);
            message.error('登录失败', 3);
        })
    }

    public render() {

        return (
            <div>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{remember: true}}
                    onFinish={this.onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: 'Please input your Username!'}]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Username"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Please input your Password!'}]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
                <div>
                    <Row gutter={16}>
                        <Col span={2}/>
                        <Col span={10}>
                            <span><a href='#/user/about'>关于我们</a></span>
                        </Col>
                        <Col span={10}>
                            <a href="mailto:175936403@qq.com">联系我们</a>
                        </Col>
                        <Col span={2}/>
                    </Row>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {states: state};
};

const mapDispatchToProps = (dispatch: any) => ({
    onUserAdd: (username: string, status: number) => dispatch(userActions.addUser(username, status))
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(NormalLoginForm);
// export default NormalLoginForm;