import React, {Component} from 'react'
import {Chart, Line, Point, Tooltip} from "bizcharts";
import {Ajax} from "../../api/axios";
import {LineData} from "../../common/common"

interface IProps {

}

interface IStat {
    linedata: Array<LineData>,
    unit: string
}


class FrpLine extends Component<IProps, IStat> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            linedata: [],
            unit: 'MB'
        };
    }

    componentDidMount() {
        Ajax.get<Array<LineData>>('/frps/trafficLine').then(res => {
            console.log(res);
            if (res.success) {
                console.log("get data sucess");
                this.setState({
                    linedata: res.data
                });
                console.log("linedata:", this.state.linedata);
            }
        })
    }

    public render() {

        return (
            <Chart
                appendPadding={[10, 0, 0, 10]}
                autoFit={true}
                height={300}
                data={this.state.linedata}
                scale={{
                    lineData: {min: 0, alias: '流量', type: 'linear-strict'},
                    lineLabel: {range: [0, 1]}
                }}
            >

                <Line position="lineLabel*lineData" label={[
                    "lineData",
                    (yValue) => {
                        return {
                            content: yValue + this.state.unit,
                            style: {
                                fill: 'blue',
                            }
                        };
                    }
                ]}
                />
                <Point position="lineLabel*lineData"/>
                <Tooltip showCrosshairs/>
            </Chart>

        );
    }
}

export default FrpLine;
