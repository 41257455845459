import {ADD_USER} from "../../common/actionTypes";

const initialState = {
    users: []
}

const userReducer = function (state = initialState, action: any) {
    console.log("users:", state.users);
    switch (action.type) {
        case ADD_USER: {
            return {
                ...state,
                users: [...state.users, action.payload]
            };
        }
        default:
            return state
    }
}

export default userReducer