import React from 'react';
import {Col, Layout, Row} from 'antd';
import './index.less';

const {Footer} = Layout;

export function DdFooter() {
    return (
        <Footer className='footer'>
            <div>
                <Row gutter={16}>
                    <Col span={8}>
                    </Col>
                    <Col span={4}>
                        <span><a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">苏ICP备2021031086号</a></span>
                    </Col>
                    <Col span={4}>
                        <span>Chu Desgin@2021-7</span>
                    </Col>
                    <Col span={8}>
                    </Col>
                </Row>
            </div>
        </Footer>
    )

}

export default DdFooter;