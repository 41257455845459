import React, {Component} from 'react'
import {Col, Row} from 'antd';
import MetricPanel from "./MetricPanel";

interface IProps {
    hostName: string,
    height: number
}

interface IState {
    host: string
}


class ZabbixStatis extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            host: props.hostName
        };
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any) {
        this.setState({
            host: nextProps.hostName
        })
    }

    render() {
        return (
            <Row>
                <Col span={12}>
                    <span>CPU使用率</span>
                    <MetricPanel hostName={this.state.host} metricName='CPU utilization' height={this.props.height}/>
                </Col>
                <Col span={12}>
                    <span>内存使用率</span>
                    <MetricPanel hostName={this.state.host} metricName='Memory utilization' height={this.props.height}/>
                </Col>
            </Row>
        )
    }
}

export default ZabbixStatis;