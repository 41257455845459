import {Avatar, Card, Image} from "antd";
import React, {Component} from "react";
import {RollbackOutlined} from '@ant-design/icons';

const {Meta} = Card;

class CloudAbout extends Component {

    public render() {
        return (
            <Card
                style={{width: 500}}
                actions={[<div><RollbackOutlined/><a href="#/user/login">返回</a></div>]}
            >
                <Meta avatar={<Avatar
                    src={<Image src={require('../../asset/img/logo.png').default}/>}/>}
                      title={<span><b>关于我们</b></span>}
                      description="这是一个家庭信息存储展示网站，不对外开放，如果需要访问，请联系管理员(175936403@qq.com)"
                />
            </Card>
        );
    }
}

export default CloudAbout;