import React, {Component} from 'react'
import {FrpTcpInfo} from "../../common/common";
import {Table, Tag} from 'antd';
import {Ajax} from "../../api/axios";

interface IProps {

}

interface IStat {
    frpTcpData: Array<FrpTcpInfo>
}

const units = [
    'bytes',
    'KB',
    'MB',
    'GB'
];

const columns = [
    {
        title: '名称',
        dataIndex: 'name',
        width: '5%'
    },
    {
        title: '端口',
        dataIndex: 'port',
        width: '5%'
    },
    {
        title: '状态',
        dataIndex: 'status',
        width: '5%',
        render: function (status: string) {
            let color = 'green';
            if (status === 'offline') {
                color = 'red';
            }
            return (
                <Tag color={color} key={status}>
                    {status}
                </Tag>
            );
        }
    },
    {
        title: '连接数',
        dataIndex: 'cur_conns',
        width: '9%'
    },
    {
        title: '入流量',
        dataIndex: 'today_traffic_in',
        width: '18%',
        render: function (traffic: number) {
            let index = 0;
            while (traffic > 1024) {
                traffic = traffic / 1024;
                index++;
            }
            let trafficStr = traffic.toFixed(2);
            return trafficStr + ' ' + units[index];
        }
    },
    {
        title: '出流量',
        dataIndex: 'today_traffic_out',
        width: '18%',
        render: function (traffic: number) {
            let index = 0;
            while (traffic > 1024) {
                traffic = traffic / 1024;
                index++;
            }
            let trafficStr = traffic.toFixed(2);
            return trafficStr + ' ' + units[index];
        }
    },
    {
        title: '最后连接时间',
        dataIndex: 'last_start_time',
        width: '20%'
    },
    {
        title: '最后结束时间',
        dataIndex: 'last_close_time',
        width: '20%'
    }
];

class FrpTcpTable extends Component<IProps, IStat> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            frpTcpData: []
        };
    }

    componentDidMount() {
        Ajax.get<Array<FrpTcpInfo>>('/frps/tcpinfo').then(res => {
            console.log(res);
            if (res.success) {
                console.log("get data sucess");
                this.setState({
                    frpTcpData: res.data
                });
                console.log("frp tcp data:", this.state.frpTcpData);
            }
        })
    }

    public render() {

        return (
            <Table columns={columns} dataSource={this.state.frpTcpData}>
            </Table>

        );
    }
}

export default FrpTcpTable;