import React, {Component} from 'react';
import {
    Chart,
    Point,
    Annotation,
    Axis,
    Coordinate,
} from 'bizcharts';
import {Ajax} from "../../api/axios";
import {message} from "antd";

interface IProps {

}

interface IState {
    data: number
}

const scale = {
    value: {
        min: 0,
        max: 1,
        tickInterval: 0.1,
        formatter: (v:number) => v * 100
    }
}


class AliyunCpu extends Component<IProps,IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            data: 0
        };
    }

    componentDidMount() {
        Ajax.get<number>('/aliyun/metric?metricName=cpu_total').then(res => {

            if (res.success) {
                console.log("get data sucess");
                this.setState({
                    data: res.data/100
                })
            }
        }).catch(function (reason) {
            console.log('catch:', reason);
            message.error('获取数据失败', 3);
        })
    }

    render() {
        return (
            <Chart
                height={300}
                data={this.state.data}
                scale={scale}
                autoFit={true}
            >
                <Coordinate
                    type="polar"
                    radius={0.75}
                    startAngle={(-1.4) * Math.PI}
                    endAngle={(0.4) * Math.PI}
                />
                <Axis
                    name="value"
                    line={null}
                    visible={false}
                    label={{
                        offset: -36,
                        style: {
                            fontSize: 18,
                            textAlign: 'center',
                            textBaseline: 'middle',
                        },
                    }}

                    grid={null}
                />
                <Point
                    position="value*1"
                    color="#1890FF"
                    shape="pointer"
                />
                <Annotation.Arc
                    start={[0, 1]}
                    end={[1, 1]}
                    style={{
                        stroke: '#CBCBCB',
                        lineWidth: 18,
                        lineDash: null,
                        lineCap: 'round',
                    }}
                />
                <Annotation.Arc
                    start={[0, 1]}
                    end={[this.state.data, 1]}
                    style={{
                        stroke: '#1890FF',
                        lineCap: 'round',
                        lineWidth: 18,
                        lineDash: null,
                    }}
                />
                <Annotation.Text
                    position={['50%', '50%']}
                    content={`${Math.round(this.state.data*100 )}%`}
                    style={{
                        fontSize: 50,
                        fill: '#262626',
                        textAlign: 'center',
                    }}
                />
            </Chart>
        )
    }
}

export default AliyunCpu;