import React, {Component} from 'react';
import {IStoreState} from "../../redux/storeType";
import {connect} from "react-redux";

interface IProps {
    value: IStoreState
}

class RightHeader extends Component<IProps> {


    render() {

        var username = "admin";
        var userInfo: IStoreState = this.props.value;
        if (userInfo.users.users != null && userInfo.users.users.length > 0){
            username = userInfo.users.users[0].username;
        }
        return (
            <div className="right">
                <span>欢迎，{username}</span>
            </div>
        );
    }
};

const mapStateToProps = (state: IStoreState) => {
    return {value: state};
};


export default connect(
    mapStateToProps
)(RightHeader);