import React, {Component} from 'react';
import './TVCalendar.less'

export default class TVCalendar extends Component {


    render() {
        return (
            <div className="TVCalendar">
                <iframe ref="iframe" scrolling="yes" frameBorder="0" title="tvcalendar"
                        style={{width: '100%', height: '100%', overflow: 'visible'}}

                        src="https://assrt.net/calendar#today"
                />
            </div>
        );
    }
}