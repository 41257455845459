import React, {Component} from 'react';
import {Layout, Menu, Row, Col, message} from 'antd';
import {Link} from "react-router-dom";
import {asideData} from "../../router/aside";
import './index.less'
import RightHeader from './RightHeader';
import {SettingOutlined} from '@ant-design/icons';

const {SubMenu} = Menu;
const {Header} = Layout;

export class CloudHeader extends Component {


    render() {

        const
            hash = window.location.hash.split('#')[1]
        const
            defaultSelectedKeys = hash.split('?')[0]
        const
            loginOut = () => {
                //清除cookie，可考虑复用
                sessionStorage.removeItem("access-token")
                message.success('退出登录')
                setTimeout(() => {
                    window.location.hash = `/user/login`
                }, 500);
            }
        //二级栏（因不清楚需求参数，any）
        const
            mapNav = (child: any): any => {
                return (
                    child.map((obj: any, index: number) => {
                        return (
                            <Menu.Item key={index + 1}>
                                <Link to={`${obj.url}`}>
                                    {obj.item}
                                </Link>
                            </Menu.Item>
                        )
                    })
                )
            }
        //一级栏（因不清楚需求参数，any）
        const
            mapAside = (): any => {
                return (
                    asideData.map((obj, index: number) => {
                        if (obj.child !== null) {
                            return (
                                <SubMenu
                                    key={index + 1}
                                    title={
                                        <span>{obj.menu}</span>
                                    }
                                >
                                    {mapNav(obj.child)}
                                </SubMenu>
                            )
                        } else {
                            return (
                                <Menu.Item key={obj.url}>
                                    <span>{obj.menu}</span>
                                    <Link to={`${obj.url}`}></Link>
                                </Menu.Item>
                            )
                        }
                    })
                )
            }
        return (
            <Header className="header" style={{zIndex: 1000}}>
                <Row>
                    <Col span={4}>
                        <div className="logo">
                            <span>CassieCloud</span>
                        </div>
                    </Col>
                    <Col span={16}>
                        <div>
                            <Menu
                                theme="light"
                                mode="horizontal"
                                defaultSelectedKeys={[defaultSelectedKeys]}
                                style={{lineHeight: '64px'}}
                            >
                                {mapAside()}
                            </Menu>
                        </div>
                    </Col>
                    <Col span={2}>
                        <RightHeader/>
                    </Col>
                    <Col span={2}>
                        <Menu
                            theme="light"
                            mode="horizontal"
                            style={{lineHeight: '64px'}}
                        >
                            <SubMenu
                                className="setting"
                                title={
                                    <span className="submenu-title-wrapper">
                                    <SettingOutlined/>设置
                                </span>
                                }
                            >
                                <Menu.Item key="loginOut" onClick={loginOut}>退出登录</Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Col>
                </Row>
            </Header>
        )
    }

}


export default CloudHeader;
