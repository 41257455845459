import {
    Home,
    Monitor,
    TVCalendar,
    SubConfig,
    TVNameConfig
} from '../views'

export default [
    {path: '/home', component: Home},
    {path: '/monitor', component: Monitor},
    {path: '/tvcalendar', component: TVCalendar},
    {path: '/subconfig', component: SubConfig},
    {path: '/tvconfig', component: TVNameConfig}
]