import React, {Component} from 'react';
import {Avatar, Card, Col, Row, Image, Space} from 'antd';
import './Home.less'
import {connect} from 'react-redux';
import {IStoreState} from "../redux/storeType";
import FrpLine from './data/FrpLine';
import FrpStatis from "./data/FrpStatis";
import AliyunCpu from "./data/AliyunCpu";
import AliyunMem from "./data/AliyunMem";
import ZabbixStatis from "./data/ZabbixStatis";
import HostCard from "./HostCard";

interface IProps {
    value?: IStoreState
}

const {Meta} = Card;

class Home extends Component<IProps> {

    render() {
        // @ts-ignore
        return (
            <div className="Home">
                <Space direction="vertical" style={{width: '100%'}}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Card title="云管理组件" bordered={false}>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/proxmox_logo.png').default}/>}/>}
                                          title={<a href="https://pve.cassiecloud.top:8006/" target="_blank">pve</a>}
                                          description="pve 虚拟化平台"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/omv.png').default}/>}/>}
                                          title={<a href="https://omv.cassiecloud.top:8090/" target="_blank">omv</a>}
                                          description="The open network attached storage solution"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/portainer.png').default}/>}/>}
                                          title={<a href="http://portainer.cassiecloud.top:9000/" target="_blank">portainer</a>}
                                          description="轻量级可视化的Docker管理工具"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/mysql.png').default}/>}/>}
                                          title={<a href="http://omv.cassiecloud.top:8088/" target="_blank">Adminer</a>}
                                          description="MySQL数据库管理组件"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/zabbix.png').default}/>}/>}
                                          title={<a href="http://zabbix.cassiecloud.top:8089/" target="_blank">Zabbix</a>}
                                          description="开源的企业级监控服务"
                                    />
                                </Card.Grid>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="云应用组件" bordered={false}>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/nextcloud.png').default}/>}/>}
                                          title={<a href="https://nextcloud.cassiecloud.top:8443/" target="_blank">NextCloud</a>}
                                          description="私有云盘，存储文档、照片和视频"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/qbittorrent.png').default}/>}/>}
                                          title={<a href="http://qb.cassiecloud.top:9001/" target="_blank" rel="noreferrer">qBittorent</a>}
                                          description="BT下载工具"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/piwigo.png').default}/>}/>}
                                          title={<a href="http://piwigo.cassiecloud.top:8092/" target="_blank" rel="noreferrer">piwigo</a>}
                                          description="照片管理系统，开源相册"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/gitea.svg').default}/>}/>}
                                          title={<a href="http://gogs.cassiecloud.top:10070/" target="_blank" rel="noreferrer">gitea</a>}
                                          description="开源轻量化Git版本管理服务"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/drone.svg').default}/>}/>}
                                          title={<a href="http://gogs.cassiecloud.top:10081/" target="_blank" rel="noreferrer">drone</a>}
                                          description="开源轻量化CI/CD平台"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/jellyfin.png').default}/>}/>}
                                          title={<a href="http://jellyfin.cassiecloud.top:8096/" target="_blank" rel="noreferrer">jellyfin</a>}
                                          description="免费家庭媒体中心"
                                    />
                                </Card.Grid>
                                <Card.Grid style={{height: 120, width: 280}}>
                                    <Meta avatar={<Avatar
                                        src={<Image src={require('./../asset/img/homeassistant.png').default}/>}/>}
                                          title={<a href="http://homeassistant.cassiecloud.top:8123/" target="_blank" rel="noreferrer">HomeAssistant</a>}
                                          description="家庭智能服务中心"
                                    />
                                </Card.Grid>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Card title="内网穿透情况" style={{height: 400}}>
                                <Row>
                                    <Col span={6}>
                                        <FrpStatis/>
                                    </Col>
                                    <Col span={18}>
                                        <FrpLine/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="云服务器情况" style={{height: 400}}>
                                <Row>
                                    <Col span={12}>
                                        <span>CPU使用率</span>
                                        <AliyunCpu/>

                                    </Col>
                                    <Col span={12}>
                                        <span>内存使用率</span>
                                        <AliyunMem/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Card title="宿主机情况" style={{height: 400}}>
                                <ZabbixStatis hostName="pve" height={300}/>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <HostCard/>
                        </Col>
                    </Row>
                </Space>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {value: state};
};


export default connect(
    mapStateToProps
)(Home);

// export default Home;
